document.addEventListener("DOMContentLoaded", function (event) {
  const importantInfoBlockBtn = document.querySelector(".important-info__link");
  const importantInfoBlock = document.querySelector("#main-important-info");

  function scrollToImportantInfo(e) {
    e.preventDefault();
    gsap.to(window, {
      duration: 1,
      scrollTo: importantInfoBlock,
      ease: "power2",
    });
  }

  importantInfoBlockBtn.addEventListener("click", scrollToImportantInfo);

  //Слайдер новостей
  //const newsSlider = document.querySelector(".news-slider");
  const newsSliderContainer = document.querySelector(".articles__cards");
  const newsSliderInit = new Swiper(newsSliderContainer, {
    // Optional parameters
    loop: false,
    breakpoints: {
      // >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      // >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // >= 1000px
      1000: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1320: {
        slidesPerView: 2.9523,
        spaceBetween: 20,
      },
      // >= 1460px
      1460: {
        slidesPerView: 3.2381,
        spaceBetween: 20,
      },
      // >= 1780px
      1780: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });
});
